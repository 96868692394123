import { routeToUrl } from "@app-routes";
import Logo from "@public/images/logo.svg";
import { Button } from "@uxf/ui/button";
import Link from "next/link";

export function HeaderHomepage() {
    return (
        <div className="uxf-typo-body items-center justify-between md:container md:flex md:h-[64px]">
            <div className="max-md:border-b max-md:border-border">
                <Logo className="mx-auto w-[248px] py-2 md:py-3" href={routeToUrl("index")} />
            </div>
            <div className="flex items-center justify-center space-x-3 py-3 font-medium text-textHigh max-md:hidden ">
                <div className="px-4 py-2">
                    <Link href={routeToUrl("auth/login", {})}>Přihlášení</Link>
                </div>
                <div className="text-buttonMedium">
                    <Button href={routeToUrl("auth/register")} className="px-4 py-2 ">
                        Registrovat školu
                    </Button>
                </div>
            </div>
            <div className="flex items-center justify-center space-x-3 py-3 font-medium text-textHigh md:hidden ">
                <div className="px-4 py-2 underline underline-offset-2">
                    <Link href={routeToUrl("auth/login", {})}>Přihlášení</Link>
                </div>
                <div className="px-4 py-2 underline underline-offset-2">
                    <Link href={routeToUrl("auth/register")}>Registrovat školu</Link>
                </div>
            </div>
        </div>
    );
}

/*
        <div className="uxf-typo-body items-center justify-between px-0 md:container md:flex md:h-[64px]">
            <div className="max-md:border-b max-md:border-border">
                <Logo className="mx-auto w-[248px] py-2 md:py-3" href={routeToUrl("index")} />
            </div>
            <div className="flex items-center justify-center space-x-3 py-3 font-medium text-textHigh max-md:hidden ">
                <div className="px-4 py-2">
                    <Link href={routeToUrl("auth/login", {})}>Přihlášení</Link>
                </div>
                <div className="text-buttonMedium">
                    <Button href={routeToUrl("auth/register")} className="px-4 py-2 ">
                        Registrovat školu
                    </Button>
                </div>
            </div>
            <div className="flex items-center justify-center space-x-3 py-3 font-medium text-textHigh md:hidden ">
                <div className="px-4 py-2 underline underline-offset-2">
                    <Link href={routeToUrl("auth/login", {})}>Přihlášení</Link>
                </div>
                <div className="px-4 py-2 underline underline-offset-2">
                    <Link href={routeToUrl("auth/register")}>Registrovat školu</Link>
                </div>
            </div>
        </div>
 */
